import React from 'react';

const About = () => {
    return (
        <div id="content">
            <h1>About</h1>
            <p>Coming soon...</p>
        </div>
    );
}

export default About;