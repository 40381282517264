import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <header>
            <div className="navbar-wrapper">
                <Link to="/" className="brand-link">FromAtoB</Link>
                <nav>
                    <Link to="/about">How it works</Link>
                    <Link to="/contact">Contact</Link>
                </nav>
            </div>
        </header>
    );
}

export default Header;