import WordSelect from './components/WordSelect';
import Header from './components/Header';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import About from './components/About';
import Contact from './components/Contact';

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/">
            <WordSelect />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>

  );
}

export default App;
