import React, { useState, useEffect } from 'react';
import axios from 'axios';
import keys from '../config/keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimesCircle as faTimesCircleSol } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle as faTimesCircleReg } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

library.add(faTimesCircleSol);
library.add(faTimesCircleReg);
library.add(faArrowRight);

const WordSelect = () => {

    const [startWort, setStartWort] = useState(null);
    const [zielWort, setZielWort] = useState(null);
    const [failedAttempts, setFailedAttempts] = useState(0);
    const [victory, setVictory] = useState(false);

    const [isSubmitting, setSubmitting] = useState(true);
    const [wordList, setWordList] = useState([]);
    const [selectedWord, setSelectedWord] = useState(null);
    const [wordHistory, setWordHistory] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
        

            const res1 = await axios.get(`${keys.API_URL}/generate_pair`)
            if (res1.status !== 200 || !res1.data || !res1.data.startWort || !res1.data.zielWort) {
                alert("an error occurred")
                return;
            }
            setStartWort(res1.data.startWort);
            setZielWort(res1.data.zielWort);

            const res = await axios.get(`${keys.API_URL}/find_association`, { params: { word: res1.data.startWort } });

            if (res.status !== 200 || !res.data || !res.data.associations) {
                alert("an error occurred")
                return;
            }

            setWordList(res.data.associations)
            setSubmitting(false);
        }
        fetchData();

    }, [])

    const handleSubmit = (e) => {
        setSubmitting(true);
        e.preventDefault();

        if (selectedWord.toLowerCase().trim().replace("n", "") === zielWort.toLowerCase().trim().replace("n", "")) {
            setVictory(true);
            return;
        }

        axios.get(`${keys.API_URL}/find_association`, { params: { word: selectedWord } })
            .then(res => {

                if (res.status !== 200 || !res.data || !res.data.associations) {
                    alert("an error occurred")
                    if (failedAttempts >= 3) {
                        return;
                    }
                    setFailedAttempts(failedAttempts + 1);
                    setSubmitting(false);
                    return;
                }
                setFailedAttempts(0);
                setWordHistory([...wordHistory, selectedWord]);
                setWordList(res.data.associations)
                setSubmitting(false);
            }).catch(ex => {
                alert("an error occurred")
                if (failedAttempts >= 3) {
                    return;
                }
                setFailedAttempts(failedAttempts + 1);
                setSubmitting(false);
                return;
            })
    }

    const renderWordList = () => {
        return wordList.map((word, index) => {
            if (word === zielWort) {
                return <button className="correctWord" key={index} disabled={isSubmitting} onClick={() => setSelectedWord(word)} type="submit"><b>{word}</b></button>
            } else {
                return <button key={index} disabled={isSubmitting} onClick={() => setSelectedWord(word)} type="submit">{word}</button>
            }
        });
    }
    const renderWordHistory = () => {
        // const reverseHistory = wordHistory.reverse();
        // console.log(wordHistory);
        // console.log(wordHistory.reverse());
        const ret = [].concat(wordHistory).map((word, index) => {
            if (index === 0) {
                return <li key={index}>{word}</li>
            } else {
                return <li key={index}> <FontAwesomeIcon icon={faArrowRight} />{word}</li>

            }
        })
        // console.log(ret);
        return ret;
    }

    const victoryClass = victory ? "wonGameVisible" : "wonGameHidden";
    return (
        [<div key="1" id="wordSelectGameWrapper">
            <div id="wordSelectGame">
                <div id="fromToPrompt">
                    <div><p className="fromToPromptLeft">from:</p><p className="fromToPromptRight">{wordHistory[wordHistory.length - 1] || startWort}</p></div>
                    <div><p className="fromToPromptLeft">to:</p><p className="fromToPromptRight">{zielWort}</p></div>
                </div>
                <form id="wordList" onSubmit={handleSubmit}>
                    {renderWordList()}
                </form>
                <button id="surrenderButton"disabled={isSubmitting} onClick={() => window.location.reload()}><FontAwesomeIcon id="surrenderDefault" icon={faTimesCircleReg} /> <FontAwesomeIcon id="surrenderHover" icon={faTimesCircleSol} /> Aufgeben</button>
                <div class={victoryClass} id="wonGame">
                    <h1>You Won!</h1>
                    <p>You needed {wordHistory.length} attempts! Another try?</p>
                    <button onClick={() => window.location.reload()}>Play again!</button>
                </div>
            </div>
        </div>,
        <div key="2" id="wordHistory">
            <ul>
                {renderWordHistory()}
            </ul>
        </div>
        ]
    );
}

export default WordSelect;