import React from 'react';

const Contact = () => {
    return (
        <div id="content">
        <a href="#impressum-german" style={{textDecoration: 'none'}}>&#8594; German version below</a>
            <h1 class="adsimple-121729848">Legal Notice</h1>
            <p class="adsimple-121729848">Information in accordance with §5 of the E-Commerce Act, §14 of the Unternehmensgesetzbuch, §63 of the Commercial Code and disclosure requirements under §25 of the Media Act.</p>
            <p class="adsimple-121729848">Spohn Philipp<br />Kupelwiesergasse 15, <br />1130 Wien, <br />Österreich</p>
            <p class="adsimple-121729848">
            </p>
            <p class="adsimple-121729848">
                <strong>Phone:</strong> +43 6506731031<br />
                <strong>Email:</strong> <a href="mailto:P_spohn@live.at">P_spohn@live.at</a>
            </p>
            <p>Source: Created with the <a href="https://www.adsimple.at/impressum-generator/" rel="noopener noreferrer" title="Impressum Generator by AdSimple">Impressum Generator</a> by AdSimple</p>
            <h2 id="eu-streitschlichtung-en" class="adsimple-121729848">
            </h2>
            <h2 id="haftung-fuer-inhalte-dieser-webseite-en" class="adsimple-121729848">
            </h2>
            <h2 id="haftung-links-webseite-en" class="adsimple-121729848">
            </h2>
            <h2 id="urheberrechtshinweis-en" class="adsimple-121729848">
            </h2>
            <h2 id="bildernachweis-en" class="adsimple-121729848">
            </h2>
            <h1 class="adsimple-121729848">Privacy policy</h1>
            <h2 id="datenschutz-en" class="adsimple-121729848">Privacy Policy</h2>
            <p>We have written this privacy policy (version 01.01.1970-121729848) to provide you with information in accordance with the requirements of the <a class="adsimple-121729848" href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32016R0679&amp%3Btid=%5Badsimpletextid]" target="_blank" rel="noopener noreferrer">General Data Protection Regulation (EU) 2016/679</a> as well as to explain what information we collect, how we use data and what choices you have as a visitor to this website.</p>
            <p>Privacy policies usually sound very technical. However, this version should describe the most important things as simply and clearly as possible. Moreover, technical terms are explained in a reader-friendly manner whenever possible. We would also like to convey that we only collect and use information via this website if there is a corresponding legal basis for it. This is certainly not possible if you give very brief technical explanations, as are often standard on the Internet when it comes to data protection. We hope you find the following explanations interesting and informative. Maybe you will also find some information that you did not know yet.<br />
Should you still have questions, we kindly ask you to follow the existing links to see further information on third-party websites, or to simply write us an email. You can find our contact information in our website’s imprint.</p>
            <h2 id="automatische-datenspeicherung-en" class="adsimple-121729848">Automatic Data Retention</h2>
            <p>Every time you visit a website nowadays, certain information is automatically created and stored, just as it happens on this website. This data should be collected as sparingly as possible, and only with good reason. By website, we mean the entirety of all websites on your domain, i.e. everything from the homepage to the very last subpage (like this one here). By domain we mean example.uk or examplepage.com.</p>
            <p>Even while you are currently visiting our website, our web server – this is the computer this website is stored on, usually automatically retains data such as the below – for reasons such as operational security or for creating access statistics etc.</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">the full address (URL) of the accessed website (e. g. https://www.examplepage.uk/examplesubpage.html/)</li>
                <li class="adsimple-121729848">browser and browser version (e.g. Chrome 87)</li>
                <li class="adsimple-121729848">the operating system used (e.g. Windows 10)</li>
                <li class="adsimple-121729848">the address (URL) of the previously visited site (referrer URL) (z. B. https://www.examplepage.uk/icamefromhere.html/)</li>
                <li class="adsimple-121729848">the host name and the IP-address of the device the website is accessed from (e.g. COMPUTERNAME and 194.23.43.121)</li>
                <li class="adsimple-121729848">date and time</li>
                <li class="adsimple-121729848">in so-called web server log files.</li>
            </ul>
            <p>As an illustration:</p>
            <p>
                <img src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
            </p>
            <p>Generally, these files are stored for two weeks and are then automatically deleted. We do not pass these data to others, but we cannot exclude the possibility that this data may be looked at by the authorities in case of illegal conduct.</p>
            <p>In short: your visit is logged by our provider (company that runs our website on servers), but we do not pass on your data!</p>
            <h2 id="cookies-en" class="adsimple-121729848">Cookies</h2>
            <p>Our website uses HTTP-cookies to store user-specific data.<br />
For your better understanding of the following Privacy Policy statement, we will explain to you below what cookies are and why they are in use.</p>
            <h3 class="adsimple-121729848">What exactly are cookies?</h3>
            <p>Every time you surf the internet, you use a browser. Common browsers are for example Chrome, Safari, Firefox, Internet Explorer and Microsoft Edge. Most websites store small text-files in your browser. These files are called cookies.</p>
            <p>What should not be dismissed, is that cookies are very useful little helpers. Nearly all websites use cookies. More accurately speaking these are HTTP-cookies, since there are also different cookies for other uses. http-cookies are small files which our website stores on your computer. These cookie files are automatically put into the cookie-folder, which is like the “brain” of your browser. A cookie consists of a name and a value. Moreover, to define a cookie, one or multiple attributes must be specified.</p>
            <p>Cookies save certain parts of your user data, such as e.g. language or personal page settings. When you re-open our website, your browser submits these “user specific” information back to our site. Thanks to cookies, our website knows who you are and offers you the settings you are familiar to. In some browsers every cookie has its own file, in others such as Firefox, all cookies are stored in one single file.</p>
            <p>There are both first-party cookies and third-party cookies. First-party cookies are created directly by our site, while third-party cookies are created by partner-websites (e.g. Google Analytics). Every cookie is individual, since every cookie stores different data. The expiration time of a cookie also varies – it can be a few minutes, or up to a few years. Cookies are no software-programs and contain no computer viruses, trojans or any other malware. Cookies also cannot access your PC’s information.</p>
            <p>This is an example of how cookie-files can look:</p>
            <p>
                <strong class="adsimple-121729848">name:</strong> _ga<br />
                <strong class="adsimple-121729848">value:</strong> GA1.2.1326744211.152121729848-9<br />
                <strong class="adsimple-121729848">purpose:</strong> differentiation between website visitors<br />
                <strong class="adsimple-121729848">expiration date:</strong> after 2 years</p>
            <p>A browser should support these minimum sizes:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">at least 4096 bytes per cookie</li>
                <li class="adsimple-121729848">at least 50 cookies per domain</li>
                <li class="adsimple-121729848">at least 3000 cookies in total</li>
            </ul>
            <h3 class="adsimple-121729848">Which types of cookies are there?</h3>
            <p>What exact cookies we use, depends on the used services. We will explain this in the following sections of the Privacy Policy statement. Firstly, we will briefly focus on the different types of HTTP-cookies.</p>
            <p>There are 4 different types of cookies:</p>
            <p>
                <strong class="adsimple-121729848">Essential Cookies<br />
                </strong>These cookies are necessary to ensure the basic function of a website. They are needed when a user for example puts a product into their shopping cart, then continues surfing on different websites and comes back later in order to proceed to the checkout. Even when the user closed their window priorly, these cookies ensure that the shopping cart does not get deleted.</p>
            <p>
                <strong class="adsimple-121729848">Purposive Cookies<br />
                </strong>These cookies collect info about the user behaviour and record if the user potentially receives any error messages. Furthermore, these cookies record the website’s loading time as well as its behaviour within different browsers.</p>
            <p>
                <strong class="adsimple-121729848">Target-orientated Cookies<br />
                </strong>These cookies care for an improved user-friendliness. Thus, information such as previously entered locations, fonts or data in forms stay saved.</p>
            <p>
                <strong class="adsimple-121729848">Advertising Cookies<br />
                </strong>These cookies are also known as targeting-Cookies. They serve the purpose of delivering individually adapted advertisements to the user. This can be very practical, but also rather annoying.</p>
            <p>Upon your first visit to a website you are usually asked which of these cookie-types you want to accept. Furthermore, this decision will of course also be saved in a cookie.</p>
            <h3 class="adsimple-121729848">How can I delete cookies?</h3>
            <p>You yourself take the decision if and how you want to use cookies. Thus, no matter what service or website cookies are from, you always have the option to delete, deactivate or only partially allow them. Therefore, you can for example block cookies of third parties but allow any other cookies.</p>
            <p>If you want change or delete cookie-settings and would like to determine which cookies have been saved to your browser, you can find this info in your browser-settings:</p>
            <p>
                <a class="adsimple-121729848" href="https://support.google.com/chrome/answer/95647?tid=121729848" target="_blank" rel="noopener noreferrer"> Chrome: Clear, enable and manage cookies in Chrome </a>
            </p>
            <p>
                <a class="adsimple-121729848" href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121729848" target="_blank" rel="noopener noreferrer"> Safari: Manage cookies and website data in Safari </a>
            </p>
            <p>
                <a class="adsimple-121729848" href=" https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121729848" target="_blank" rel="noopener noreferrer"> Firefox: Clear cookies and site data in Firefox </a>
            </p>
            <p>
                <a class="adsimple-121729848" href=" https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121729848" target="_blank" rel="noopener noreferrer"> Internet Explorer: Delete and manage cookies </a>
            </p>
            <p>
                <a class="adsimple-121729848" href=" https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121729848" target="_blank" rel="noopener noreferrer"> Microsoft Edge: Delete cookies in Microsoft Edge </a>
            </p>
            <p>If you generally do not want to allow any cookies at all, you can set up your browser in a way, to notify you whenever a potential cookie is about to be set. This gives you the opportunity to manually decide to either permit or deny the placement of every single cookie. The settings for this differ from browser to browser. Therefore, it might be best for you to search for the instructions in Google. If you are using Chrome, you could for example put the search phrase “delete cookies Chrome” or “deactivate cookies Chrome” into Google.</p>
            <h3 class="adsimple-121729848">How is my data protected?</h3>
            <p>There is a “cookie policy” that has been in place since 2009. It states that the storage of cookies requires the user’s consent. However, among the countries of the EU, these guidelines are often met with mixed reactions. In Austria the guidelines have been implemented in § 96 section 3 of the Telecommunications Act (TKG).</p>
            <p>If you want to learn more about cookies and do not mind technical documentation, we recommend <a class="adsimple-121729848" href="https://tools.ietf.org/html/rfc6265" target="_blank" rel="nofollow noreferrer noopener">https://tools.ietf.org/html/rfc6265</a>, the Request for Comments of the Internet Engineering Task Force (IETF) called “HTTP State Management Mechanism”.</p>
            <h2 id="rechte-dsgvo-en" class="adsimple-121729848">Rights in accordance with the General Data Protection Regulation</h2>
            <p>You are granted the following rights in accordance with the provisions of the <a class="adsimple-121729848" href="https://gdpr-info.eu/" target="_blank" rel="noopener noreferrer">GDPR</a> (General Data Protection Regulation) and the Austrian <a class="adsimple-121729848" href="https://www.ris.bka.gv.at/Dokumente/Erv/ERV_1999_1_165/ERV_1999_1_165.html" target="_blank" rel="noopener noreferrer">Data Protection Act (DSG)</a>:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">right to rectification (article 16 GDPR)</li>
                <li class="adsimple-121729848">right to erasure (“right to be forgotten“) (article 17 GDPR)</li>
                <li class="adsimple-121729848">right to restrict processing (article 18 GDPR)</li>
                <li class="adsimple-121729848">righ to notification – notification obligation regarding rectification or erasure of personal data or restriction of processing (article 19 GDPR)</li>
                <li class="adsimple-121729848">right to data portability (article 20 GDPR)</li>
                <li class="adsimple-121729848">Right to object (article 21 GDPR)</li>
                <li class="adsimple-121729848">right not to be subject to a decision based solely on automated processing – including profiling – (article 22 GDPR)</li>
            </ul>
            <p>If you think that the processing of your data violates the data protection law, or that your data protection rights have been infringed in any other way, you can lodge a complaint with your respective regulatory authority. For Austria this is the data protection authority, whose website you can access at <a class="adsimple-121729848" href="https://www.data-protection-authority.gv.at/?tid=121729848">https://www.data-protection-authority.gv.at/</a>.</p>
            <h2 id="auswertung-des-besucherverhaltens-en" class="adsimple-121729848">Evaluation of Visitor Behaviour</h2>
            <p>In the following Privacy Policy, we will inform you on if and how we evaluate the data of your visit to this website. The evaluation is generally made anonymously, and we cannot link to you personally based on your behaviour on this website.</p>
            <p>You can find out more about how to disagree with the evaluation of visitor data, in the Privacy Policy below.</p>
            <h2 id="tls-verschluesselung-https-en" class="adsimple-121729848">TLS encryption with https</h2>
            <p>The terms TLS, encryption and https sound very technical, which they are indeed. We use HTTPS (Hypertext Transfer Protocol Secure) to securely transfer data on the Internet.<br />
This means that the entire transmission of all data from your browser to our web server is secured – nobody can “listen in”.</p>
            <p>We have thus introduced an additional layer of security and meet privacy requirements through technology design <a class="adsimple-121729848" href="https://eur-lex.europa.eu/legal-content/en/TXT/HTML/?uri=CELEX:32016R0679&from=EN&tid=121729848" target="_blank" rel="noopener noreferrer">Article 25 Section 1 GDPR</a>). With the use of TLS (Transport Layer Security), which is an encryption protocol for safe data transfer on the internet, we can ensure the protection of confidential information.<br />
You can recognise the use of this safeguarding tool by the little lock-symbol <img src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https-211141072.svg" alt="" width="17" height="18" />, which is situated in your browser’s top left corner in the left of the internet address (e.g. examplepage.uk), as well as by the display of the letters https (instead of http) as a part of our web address.<br />
If you want to know more about encryption, we recommend you to do a Google search for “Hypertext Transfer Protocol Secure wiki” to find good links to further information.</p>
            <h2 id="google-fonts-datenschutzerklaerung-en" class="adsimple-121729848">Google Fonts Privacy Policy</h2>
            <p>On our website we use Google Fonts, from the company Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA).</p>
            <p>To use Google Fonts, you must log in and set up a password. Furthermore, no cookies will be saved in your browser. The data (CSS, Fonts) will be requested via the Google domains fonts.googleapis.com and fonts.gstatic.com. According to Google, all requests for CSS and fonts are fully separated from any other Google services. If you have a Google account, you do not need to worry that your Google account details are transmitted to Google while you use Google Fonts. Google records the use of CSS (Cascading Style Sheets) as well as the utilised fonts and stores these data securely. We will have a detailed look at how exactly the data storage works.</p>
            <h3 class="adsimple-121729848">What are Google Fonts?</h3>
            <p>Google Fonts (previously Google Web Fonts) is a list of over 800 fonts which href=”https://en.wikipedia.org/wiki/Google?tid=121729848”>Google LLC provides its users for free.</p>
            <p>Many of these fonts have been published under the SIL Open Font License license, while others have been published under the Apache license. Both are free software licenses.</p>
            <h3 class="adsimple-121729848">Why do we use Google Fonts on our website?</h3>
            <p>With Google Fonts we can use different fonts on our website and do not have to upload them to our own server. Google Fonts is an important element which helps to keep the quality of our website high. All Google fonts are automatically optimised for the web, which saves data volume and is an advantage especially for the use of mobile terminal devices. When you use our website, the low data size provides fast loading times. Moreover, Google Fonts are secure Web Fonts. Various image synthesis systems (rendering) can lead to errors in different browsers, operating systems and mobile terminal devices. These errors could optically distort parts of texts or entire websites. Due to the fast Content Delivery Network (CDN) there are no cross-platform issues with Google Fonts. All common browsers (Google Chrome, Mozilla Firefox, Apple Safari, Opera) are supported by Google Fonts, and it reliably operates on most modern mobile operating systems, including Android 2.2+ and iOS 4.2+ (iPhone, iPad, iPod). We also use Google Fonts for presenting our entire online service as pleasantly and as uniformly as possible.</p>
            <h3 class="adsimple-121729848">Which data is saved by Google?</h3>
            <p>Whenever you visit our website, the fonts are reloaded by a Google server. Through this external cue, data gets transferred to Google’s servers. Therefore, this makes Google recognise that you (or your IP-address) is visiting our website. The Google Fonts API was developed to reduce the usage, storage and gathering of end user data to the minimum needed for the proper depiction of fonts. What is more, API stands for „Application Programming Interface“ and works as a software data intermediary.</p>
            <p>Google Fonts stores CSS and font requests safely with Google, and therefore it is protected. Using its collected usage figures, Google can determine how popular the individual fonts are. Google publishes the results on internal analysis pages, such as Google Analytics. Moreover, Google also utilises data of ist own web crawler, in order to determine which websites are using Google fonts. This data is published in Google Fonts’ BigQuery database. Enterpreneurs and developers use Google’s webservice BigQuery to be able to inspect and move big volumes of data.</p>
            <p>One more thing that should be considered, is that every request for Google Fonts automatically transmits information such as language preferences, IP address, browser version, as well as the browser’s screen resolution and name to Google’s servers. It cannot be clearly identified if this data is saved, as Google has not directly declared it.</p>
            <h3 class="adsimple-121729848">How long and where is the data stored?</h3>
            <p>Google saves requests for CSS assets for one day in a tag on their servers, which are primarily located outside of the EU. This makes it possible for us to use the fonts by means of a Google stylesheet. With the help of a stylesheet, e.g. designs or fonts of a website can get changed swiftly and easily.</p>
            <p>Any font related data is stored with Google for one year. This is because Google’s aim is to fundamentally boost websites’ loading times. With millions of websites referring to the same fonts, they are buffered after the first visit and instantly reappear on any other websites that are visited thereafter. Sometimes Google updates font files to either reduce the data sizes, increase the language coverage or to improve the design.</p>
            <h3 class="adsimple-121729848">How can I delete my data or prevent it being stored?</h3>
            <p>The data Google stores for either a day or a year cannot be deleted easily. Upon opening the page this data is automatically transmitted to Google. In order to clear the data ahead of time, you have to contact Google’s support at <a class="adsimple-121729848" href="https://support.google.com/?hl=en-GB&tid=121729848">https://support.google.com/?hl=en-GB&tid=121729848</a>. The only way for you to prevent the retention of your data is by not visiting our website.</p>
            <p>Unlike other web fonts, Google offers us unrestricted access to all its fonts. Thus, we have a vast sea of font types at our disposal, which helps us to get the most out of our website. You can find out more answers and information on Google Fonts at <a class="adsimple-121729848" href="https://developers.google.com/fonts/faq?tid=121729848">https://developers.google.com/fonts/faq?tid=121729848</a>. While Google does address relevant elements on data protection at this link, it does not contain any detailed information on data retention.<br />
It proofs rather difficult to receive any precise information on stored data by Google.</p>
            <p>On <a class="adsimple-121729848" href="https://policies.google.com/privacy?hl=de&tid=121729848">https://policies.google.com/privacy?hl=en-GB</a> you can read more about what data is generally collected by Google and what this data is used for.</p>
            <h2 id="font-awesome-datenschutzerklaerung-en" class="adsimple-121729848">Font Awesome Privacy Policy</h2>
            <p>On our website we use Font Awesome by the American company Fonticons (307 S. Main St., Suite 202, Bentonville, AR 72712, USA). Upon your visit to one of our websites, the Font Awesome web font, i.e. the icons, are loaded via the Font Awesome Content Delivery Network (CDN). This way texts, fonts and icons are displayed appropriately on every device. In this privacy policy we will go into more detail on data storage and data processing by this service.</p>
            <h3 class="adsimple-121729848">What is Font Awesome?</h3>
            <p>Icons play an increasingly important role on websites. Font Awesome is a web font specifically designed for web designers and web developers. With Font Awesome icons can for example be scaled and coloured as desired using the CSS stylesheet language. Thus, they now replace old picture icons. Font Awesome CDN is the easiest way to load icons or fonts onto your website. To do this, we only had to embed a short line of code into our website.</p>
            <h3 class="adsimple-121729848">Why do we use Font Awesome on our website?</h3>
            <p>Font Awesome enables our websites’ content to be depicted better. This eases your navigation on our website, and helps you grasp its content better. The icons can sometimes even be used to replace whole words and save space. This is particularly useful when optimising content specifically for smartphones. The icons are inserted as HMTL code instead of as an image, which allows us to edit the icons with CSS exactly as we want. Simultaneously, Font Awesome also lets us improve our loading speed, as it only contains HTML elements and no icon images. All these advantages help us to make our website even clearer, faster and more refined for you.</p>
            <h3 class="adsimple-121729848">Which data are stored by Font Awesome?</h3>
            <p>The Font Awesome Content Delivery Network (CDN) is used to load icons and symbols. CDNs are networks of servers that are distributed around the world. They make it possible to quickly load files from locations in close proximity. When you open one of our pages, the respective icons will be provided by Font Awesome.</p>
            <p>For the web fonts to be loaded, your browser has to connect to the servers of Fonticons, Inc. For this, your IP address will be identified. Font Awesome also collects data on which icon files are downloaded, as well as when they are downloaded. Furthermore, technical data such as your browser version, screen resolution or the time when you accessed the page are also transmitted.</p>
            <p>These data are collected and stored for the following reasons:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">to optimise Content Delivery Networks</li>
                <li class="adsimple-121729848">to identify and fix technical errors</li>
                <li class="adsimple-121729848">to protect CDNs from misuse and attacks</li>
                <li class="adsimple-121729848">to calculate fees from Font Awesome Pro customers</li>
                <li class="adsimple-121729848">to identify the popularity of icons</li>
                <li class="adsimple-121729848">to establish which computer and software you are using</li>
            </ul>
            <p>If your browser does not allow web fonts, one of your PC’s standard fonts will be used automatically. Moreover, as far as we are currently aware, no cookies will be set. We are keeping in contact with Font Awesome’s privacy department and will let you know as soon as we find out more.</p>
            <h3 class="adsimple-121729848">How long and where are the data stored?</h3>
            <p>Font Awesome stores data about the use of the Content Delivery Network also on servers in the United States of America. However, the CDN servers are located all across the world and store user data in your proximity. The data is usually only stored for a few weeks in an identifiable form. Aggregated statistics on the use of the CDNs may also be stored for longer. However, these do not include any personal data.</p>
            <h3 class="adsimple-121729848">How can I delete my data or prevent data retention?</h3>
            <p>As far as we are aware, Font Awesome does not store any personal data via Content Delivery Networks. If you do not want data about the used icons to be stored, you will unfortunately not be able to visit our website. If your browser does not allow web fonts, no data will be transmitted or saved. In this case your computer’s default font will be used.</p>
            <p>If you want to find out more about Font Awesome and their data handling, we recommend you to read their privacy policy at <a class="adsimple-121729848" href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a> along with the help page at <a class="adsimple-121729848" href="https://fontawesome.com/help?tid=121729848">https://fontawesome.com/help</a>.</p>
            <h2 id="web-analytics-en" class="adsimple-121729848">
            </h2>
            <h2 id="google-analytics-datenschutzerklaerung-en" class="adsimple-121729848">Google Analytics Privacy Policy</h2>
            <p>We use the tracking and analysis tool Google Analytics (GA) of the US-American company Google LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA). Google Analytics collects data on your actions on our website. Whenever you click a link for example, this action is saved in a cookie and transferred to Google Analytics. With the help of reports which we receive from Google Analytics, we can adapt our website and our services better to your wishes. In the following, we will explain the tracking tool in more detail, and most of all, we will inform you what data is saved and how you can prevent this.</p>
            <h3 class="adsimple-121729848">What is Google Analytics?</h3>
            <p>Google Analytics is a tracking tool with the purpose of conducting data traffic analysis of our website. For Google Analytics to work, there is a tracking code integrated to our website. Upon your visit to our website, this code records various actions you perform on your website. As soon as you leave our website, this data is sent to the Google Analytics server, where it is stored.</p>
            <p>Google processes this data and we then receive reports on your user behaviour. These reports can be one of the following:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">Target audience reports: With the help of target audience reports we can get to know our users better and can therefore better understand who is interested in our service.</li>
                <li class="adsimple-121729848">Advertising reports: Through advertising reports we can analyse our online advertising better and hence improve it.</li>
                <li class="adsimple-121729848">Acquisition reports: Acquisition reports provide us helpful information on how we can get more people enthusiastic about our service.</li>
                <li class="adsimple-121729848">Behaviour reports: With these reports, we can find out how you interact with our website. By the means of behaviour reports, we can understand what path you go on our website and what links you click.</li>
                <li class="adsimple-121729848">Conversion reports: A conversion is the process of leading you to carry out a desired action due to a marketing message. An example of this would be transforming you from a mere website visitor into a buyer or a newsletter subscriber. Hence, with the help of these reports we can see in more detail, if our marketing measures are successful with you. Our aim is to increase our conversion rate.</li>
                <li class="adsimple-121729848">Real time reports: With the help of these reports we can see in real time, what happens on our website. It makes us for example see, we can see how many users are reading this text right now.</li>
            </ul>
            <h3 class="adsimple-121729848">Why do we use Google Analytics on our website?</h3>
            <p>The objective of our website is clear: We want to offer you the best possible service. Google Analytics’ statistics and data help us with reaching this goal.</p>
            <p>Statistically evaluated data give us a clear picture of the strengths and weaknesses of our website. On the one hand, we can optimise our page in a way, that makes it easier to be found by interested people on Google. On the other hand, the data helps us to get a better understanding of you as our visitor. Therefore, we can very accurately find out what we must improve on our website, in order to offer you the best possible service. The analysis of that data also enables us to carry out our advertising and marketing measures in a more individual and more cost-effective way. After all, it only makes sense to show our products and services exclusively to people who are interested in them.</p>
            <h3 class="adsimple-121729848">What data gets stored by Google Analytics?</h3>
            <p>With the aid of a tracking code, Google Analytics creates a random, unique ID which is connected to your browser cookie. That way, Google Analytics recognises you as a new user. The next time you visit our site, you will be recognised as a “recurring” user. All data that is collected gets saved together with this very user ID. Only this is how it is made possible for us to evaluate and analyse pseudonymous user profiles.</p>
            <p>Your interactions on our website are measures by tags such as cookies and app instance IDs. Interactions are all kinds of actions that you perform on our website. If you are also using other Google systems (such as a Google Account), data generated by Google Analytics can be linked with third-party cookies. Google does not pass on any Google Analytics data, unless we as the website owners authorise it. In case it is required by law, exceptions can occur.</p>
            <p>The following cookies are used by Google Analytics:</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> _ga<br />
                <strong class="adsimple-121729848">Value:</strong>2.1326744211.152121729848-5<br />
                <strong class="adsimple-121729848">Purpose:</strong> By deafault, analytics.js uses the cookie _ga, to save the user ID. It generally serves the purpose of differenciating between website visitors.<br />
                <strong class="adsimple-121729848">Expiration date:</strong> After 2 years</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> _gid<br />
                <strong class="adsimple-121729848">Value:</strong>2.1687193234.152121729848-1<br />
                <strong class="adsimple-121729848">Purpose:</strong> This cookie also serves the purpose of differentiating between website users<br />
                <strong class="adsimple-121729848">Expiration date:</strong> After 24 hours</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br />
                <strong class="adsimple-121729848">Value:</strong> 1<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> It is used for decreasing the demand rate. If Google Analytics is provided via Google Tag Manager, this cookie gets the name _dc_gtm_ &lt;property-id&gt;.<br />
                <strong class="adsimple-121729848">Expiration date: </strong>After 1 minute</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> AMP_TOKEN<br />
                <strong class="adsimple-121729848">Value:</strong> No information<br />
                <strong class="adsimple-121729848">Purpose:</strong> This cookie has a token which is used to retrieve the user ID by the AMP Client ID Service. Other possible values suggest a logoff, a request or an error.<br />
                <strong class="adsimple-121729848">Expiration date:</strong> After 30 seconds up to one year</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utma<br />
                <strong class="adsimple-121729848">Value:</strong>1564498958.1564498958.1564498958.1<br />
                <strong class="adsimple-121729848">Purpose:</strong> With this cookie your behaviour on the website can be tracked and the site performance can be measured. The cookie is updated every time the information is sent to Google Analytics.<br />
                <strong class="adsimple-121729848">Expiration date:</strong> After 2 years</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utmt<br />
                <strong class="adsimple-121729848">Value:</strong> 1<br />
                <strong class="adsimple-121729848">Purpose:</strong> Just like _gat_gtag_UA_&lt;property-id&gt; this cookie is used for keeping the requirement rate in check.<br />
                <strong class="adsimple-121729848">Expiration date:</strong> Afer 10 minutes</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utmb<br />
                <strong class="adsimple-121729848">Value:</strong>3.10.1564498958<br />
                <strong class="adsimple-121729848">Purpose:</strong> This cookie is used to determine new sessions. It is updated every time new data or information gets sent to Google Analytics.<br />
                <strong class="adsimple-121729848">Expiration date:</strong> After 30 minutes</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utmc<br />
                <strong class="adsimple-121729848">Value:</strong> 167421564<br />
                <strong class="adsimple-121729848">Purpose:</strong> This cookie is used to determine new sessions for recurring visitors. It is therefore a session cookie, and only stays saved until you close the browser again.<br />
                <strong class="adsimple-121729848">Expiration date:</strong> After closing the browser</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utmz<br />
                <strong class="adsimple-121729848">Value:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br />
                <strong class="adsimple-121729848">Purpose:</strong> This cookie is used to identify the source of our website’s visitor number. This means, that the cookie saves information on where you came to our website from. This could be another site or an advertisement.<br />
                <strong class="adsimple-121729848">Expiration date:</strong> After 6 months</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utmv<br />
                <strong class="adsimple-121729848">Value:</strong> No information<br />
                <strong class="adsimple-121729848">Purpose:</strong> The cookie is used to store custom user data. It gets updated whenever information is sent to Google Analytics.<br />
                <strong class="adsimple-121729848">Expiration date:</strong> After 2 years</p>
            <p>
                <strong class="adsimple-121729848">Note:</strong> This list is by no means exhaustive, since Google are repeatedly changing the use of their cookies.</p>
            <p>Below we will give you an overview of the most important data that can be evaluated by Google Analytics:</p>
            <p>
                <strong class="adsimple-121729848">Heatmaps:</strong> Google creates so-called Heatmaps an. These Heatmaps make it possible to see the exact areas you click on, so we can get information on what routes you make on our website.</p>
            <p>
                <strong class="adsimple-121729848">Session duration:</strong> Google calls the time you spend on our website without leaving it session duration. Whenever you are inactive for 20 minutes, the session ends automatically.</p>
            <p>
                <strong class="adsimple-121729848">Bounce rate</strong> If you only look at one page of our website and then leave our website again, it is called a bounce.</p>
            <p>
                <strong class="adsimple-121729848">Account creation:</strong> If you create an account or make an order on our website, Google Analytics collects this data.</p>
            <p>
                <strong class="adsimple-121729848">IP-Address:</strong> The IP address is only shown in a shortened form, to make it impossible to clearly allocate it.</p>
            <p>
                <strong class="adsimple-121729848">Location:</strong> Your approximate location and the country you are in can be defined by the IP address. This process is called IP location determination.</p>
            <p>
                <strong class="adsimple-121729848">Technical information:</strong> Information about your browser type, your internet provider and your screen resolution are called technical information.</p>
            <p>
                <strong class="adsimple-121729848">Source:</strong> Both, Google Analytics as well as ourselves, are interested what website or what advertisement led you to our site.</p>
            <p>Further possibly stored data includes contact data, potential reviews, playing media (e.g. when you play a video on our site), sharing of contents via social media or adding our site to your favourites. This list is not exhaustive and only serves as general guidance on Google Analytics’ data retention.</p>
            <h3 class="adsimple-121729848">How long and where is the data saved?</h3>
            <p>Google has servers across the globe. Most of them are in America and therefore your data is mainly saved on American servers. Here you can read detailed information on where Google’s data centres are located: <a class="adsimple-121729848" href="https://www.google.com/about/datacenters/inside/locations/?hl=en" target="_blank" rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=en</a>
            </p>
            <p>Your data is allocated to various physical data mediums. This has the advantage of allowing to retrieve the data faster, and of protecting it better from manipulation. Every Google data centre has respective emergency programs for your data. Hence, in case of a hardware failure at Google or a server error due to natural disasters, the risk for a service interruption stays relatively low.</p>
            <p>Google Analytics has a 26 months standardised period of retaining your user data. After this time, your user data is deleted. However, we have the possibility to choose the retention period of user data ourselves. There are the following five options:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">Deletion after 14 months</li>
                <li class="adsimple-121729848">Deletion after 26 months</li>
                <li class="adsimple-121729848">Deletion after 38 months</li>
                <li class="adsimple-121729848">Deletion after 50 months</li>
                <li class="adsimple-121729848">No automatical deletion</li>
            </ul>
            <p>As soon as the chosen period is expired, the data is deleted once a month. This retention period applies to any of your data which is linked to cookies, user identification and advertisement IDs (e.g. cookies of the DoubleClick domain). Any report results are based on aggregated information and are stored independently of any user data. Aggregated information is a merge of individual data into a single and bigger unit.</p>
            <h3 class="adsimple-121729848">How can I delete my data or prevent data retention?</h3>
            <p>Under the provisions of the European Union’s data protection law, you have the right to obtain information on your data and to update, delete or restrict it. With the help of a browser add on that can deactivate Google Analytics’ JavaScript (ga.js, analytics.js, dc.js), you can prevent Google Analytics from using your data. You can download this add on at <a class="adsimple-121729848" href="https://tools.google.com/dlpage/gaoptout?hl=en-GB" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=en-GB</a>. Please consider that this add on can only deactivate any data collection by Google Analytics.</p>
            <p>Should you generally want to deactivate, delete or manage all cookies (independently of Google Analytics), you can use one of the guides that are available for any browser:</p>
            <p>
                <a class="adsimple-121729848" href="https://support.google.com/chrome/answer/95647?tid=121729848" target="_blank" rel="noopener noreferrer"> Chrome: Clear, enable and manage cookies in Chrome </a>
            </p>
            <p>
                <a class="adsimple-121729848" href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121729848" target="_blank" rel="noopener noreferrer"> Safari: Manage cookies and website data in Safari </a>
            </p>
            <p>
                <a class="adsimple-121729848" href=" https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121729848" target="_blank" rel="noopener noreferrer"> Firefox: Clear cookies and site data in Firefox </a>
            </p>
            <p>
                <a class="adsimple-121729848" href=" https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121729848" target="_blank" rel="noopener noreferrer"> Internet Explorer: Delete and manage cookies </a>
            </p>
            <p>
                <a class="adsimple-121729848" href=" https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121729848" target="_blank" rel="noopener noreferrer"> Microsoft Edge: Delete cookies in Microsoft Edge </a>
            </p>
            <p>Google Analytics is an active participant of the EU-U.S. Privacy Shield Framework, which regulates correct and save transfer of personal data.<br />
You can find more information on this at <a class="adsimple-121729848" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" target="_blank" rel="nofollow noreferrer noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=121729848</a>. We hope we were able to make you more familiar with the most important information on Google Analytics’ data processing. If you want to learn more about the tracking service, we recommend both of the following links: <a class="adsimple-121729848" href="https://marketingplatform.google.com/about/analytics/terms/gb/" target="_blank" rel="noopener noreferrer">https://marketingplatform.google.com/about/analytics/terms/gb/</a> and <a class="adsimple-121729848" href="https://support.google.com/analytics/answer/6004245?hl=en" target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=en</a>.</p>
            <h2 id="google-tag-manager-datenschutzerklaerung-en" class="adsimple-121729848">Google Tag Manager Privacy Policy</h2>
            <p>We use Google Tag Manager by the company Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) for our website.<br />
This Tag Manager is one of Google’s many helpful marketing products. With it, we can centrally integrate and manage code sections of various tracking tools, that we use on our website.</p>
            <p>In this privacy statement we will explain in more detail, what Google Tag Manager does, why we use it and to what extent your data is processed.</p>
            <h3 class="adsimple-121729848">What is Google Tag Manager?</h3>
            <p>Google Tag Manager is an organising tool with which we can integrate and manage website tags centrally and via a user interface. Tags are little code sections which e.g. track your activities on our website. For this, segments of JavaScript code are integrated to our site’s source text. The tags often come from Google’s intern products, such as Google Ads or Google Analytics, but tags from other companies can also be integrated and managed via the manager. Since the tags have different tasks, they can collect browser data, feed marketing tools with data, embed buttons, set cookies and track users across several websites.</p>
            <h3 class="adsimple-121729848">Why do we use Google Tag Manager for our website?</h3>
            <p>Everybody knows: Being organised is important! Of course, this also applies to maintenance of our website. In order to organise and design our website as well as possible for you and anyone who is interested in our products and services, we rely on various tracking tools, such as Google Analytics.<br />
The collected data shows us what interests you most, which of our services we should improve, and which other persons we should also display our services to. Furthermore, for this tracking to work, we must implement relevant JavaScript Codes to our website. While we could theoretically integrate every code section of every tracking tool separately into our source text, this would take too much time and we would lose overview. This is the reason why we use Google Tag Manager. We can easily integrate the necessary scripts and manage them from one place. Additionally, Google Tag Manager’s user interface is easy to operate, and requires no programming skills. Therefore, we can easily keep order in our jungle of tags.</p>
            <h3 class="adsimple-121729848">What data is saved by Google Tag Manager?</h3>
            <p>Tag Manager itself is a domain that neither uses cookies nor stores data. It merely functions as an “administrator“ of implemented tags. Data is collected by the individual tags of the different web analysis tools. Therefore, in Google Tag Manager the data is sent to the individual tracking tools and does not get saved.</p>
            <p>However, with the integrated tags of different web analysis tools such as Google Analytics, this is quite different. Depending on the analysis tool used, various data on your internet behaviour is collected, stored and processed with the help of cookies. Please read our texts on data protection for more information on the articular analysis and tracking tools we use on our website.</p>
            <p>We allowed Google via the account settings for the Tag Manager to receive anonymised data from us. However, this exclusively refers to the use of our Tag Manager and not to your data, which are saved via code sections. We allow Google and others, to receive selected data in anonymous form. Therefore, we agree to the anonymised transfer of our website data. However, even after extensive research we could not find out what summarised and anonymous data it is exactly that gets transmitted. What we do know is that Google deleted any info that could identify our website. Google combines the data with hundreds of other anonymous website data and creates user trends as part of benchmarking measures. Benchmarking is a process of comparing a company’s results with the ones of competitors. As a result, processes can be optimised based on the collected information.</p>
            <h3 class="adsimple-121729848">How long and where is the data saved?</h3>
            <p>When Google stores data, this is done on Google’s own servers. These servers are located all over the world, with most of them being in America. At <a class="adsimple-121729848" href="https://www.google.com/about/datacenters/inside/locations/?hl=en" target="_blank" rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=en</a> you can read in detail where Google’s servers are.</p>
            <p>In our individual data protection texts on the different tools you can find out how long the respective tracking tools save your data.</p>
            <h3 class="adsimple-121729848">How can I delete my data or prevent data retention?</h3>
            <p>Google Tag Manager itself does not set any cookies but manages different tracking websites’ tags. In our data protection texts on the different tracking tools you can find detailed information on how you can delete or manage your data.</p>
            <p>Google actively participates in the EU-U.S. Privacy Shield Framework, which regulates safe transfer of personal data. You can find more information at <a class="adsimple-121729848" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=121729848</a>. If you want to learn more about Google Tag Manager, we recommend you to read <a class="adsimple-121729848" href="https://marketingplatform.google.com/intl/en_uk/about/tag-manager/" target="_blank" rel="noopener noreferrer">https://marketingplatform.google.com/intl/en_uk/about/tag-manager/</a>.</p>
All texts are copyrighted.<p>Source: Created with the <a href="https://www.adsimple.at/datenschutz-generator/" rel="noopener noreferrer"  title="Datenschutz Generator by AdSimple">Datenschutz Generator</a> by AdSimple</p>

            <h1 class="adsimple-121729848" id="impressum-german">Impressum</h1>
            <p class="adsimple-121729848">Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>
            <p class="adsimple-121729848">Spohn Philipp<br />Kupelwiesergasse 15, <br />1130 Wien, <br />Österreich</p>
            <p class="adsimple-121729848">
            </p>
            <p class="adsimple-121729848">
                <strong>Tel.:</strong> +43 6506731031<br />
                <strong>E-Mail:</strong> <a href="mailto:P_spohn@live.at">P_spohn@live.at</a>
            </p>
            <p>Quelle: Erstellt mit dem <a href="https://www.adsimple.at/impressum-generator/" rel="noopener noreferrer"  title="Impressum Generator von AdSimple">Impressum Generator</a> von AdSimple</p>
            <h2 id="eu-streitschlichtung" class="adsimple-121729848">EU-Streitschlichtung</h2>
            <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br />
Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter <a class="adsimple-121729848" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE" target="_blank" rel="noopener noreferrer">http://ec.europa.eu/odr?tid=121729848</a> zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
            <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            <h2 id="haftung-fuer-inhalte-dieser-webseite" class="adsimple-121729848">Haftung für Inhalte dieser Website</h2>
            <p>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen.  Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Website übernehmen, speziell für jene, die seitens Dritter bereitgestellt wurden. Als Diensteanbieter sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
            <p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit davon unberührt.</p>
            <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.</p>
            <h2 id="haftung-links-webseite" class="adsimple-121729848">Haftung für Links auf dieser Webseite</h2>
            <p>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
            <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.</p>
            <h2 id="urheberrechtshinweis" class="adsimple-121729848">Urheberrechtshinweis</h2>
            <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
            <p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.</p>
            <h2 id="bildernachweis" class="adsimple-121729848">Bildernachweis</h2>
            <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
            <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">Fotograf Mustermann</li>
            </ul>
            <h1 class="adsimple-121729848">Datenschutzerklärung</h1>
            <h2 id="datenschutz" class="adsimple-121729848">Datenschutz</h2>
            <p>Wir haben diese Datenschutzerklärung (Fassung 01.01.1970-121729848) verfasst, um Ihnen gemäß der Vorgaben der <a class="adsimple-121729848" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=121729848" target="_blank" rel="noopener noreferrer">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten wir verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.</p>
            <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch. Diese Version soll Ihnen hingegen die wichtigsten Dinge so einfach und klar wie möglich beschreiben. Soweit es möglich ist, werden technische Begriffe leserfreundlich erklärt. Außerdem möchten wir vermitteln, dass wir mit dieser Website nur dann Informationen sammeln und verwenden, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
Wenn trotzdem Fragen bleiben, möchten wir Sie bitten den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen, oder uns einfach eine E-Mail zu schreiben. Unsere Kontaktdaten finden Sie im Impressum.</p>
            <h2 id="automatische-datenspeicherung" class="adsimple-121729848">Automatische Datenspeicherung</h2>
            <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Website. Diese gesammelten Daten sollten möglichst sparsam und nur mit Begründung gesammelt werden werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf Ihrer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
            <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver – das ist der Computer auf dem diese Webseite gespeichert ist –  aus Gründen der Betriebssicherheit, zur Erstellung von Zugriffsstatistik usw. in der Regel automatisch Daten wie</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B. https://www.beispielwebsite.de/beispielunterseite.html/)</li>
                <li class="adsimple-121729848">Browser und Browserversion (z. B. Chrome 87)</li>
                <li class="adsimple-121729848">das verwendete Betriebssystem (z. B. Windows 10)</li>
                <li class="adsimple-121729848">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. https://www.beispielquellsite.de/vondabinichgekommen.html/)</li>
                <li class="adsimple-121729848">den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
                <li class="adsimple-121729848">Datum und Uhrzeit</li>
                <li class="adsimple-121729848">in Dateien, den sogenannten Webserver-Logfiles.</li>
            </ul>
            <p>Zur Veranschaulichung:</p>
            <p>
                <img src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
            </p>
            <p>In der Regel werden diese Dateien zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
            <p>Kurz gesagt: Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht weiter!</p>
            <h2 id="cookies" class="adsimple-121729848">Cookies</h2>
            <p>Unsere Webseite verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br />
Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</p>
            <h3 class="adsimple-121729848">Was genau sind Cookies?</h3>
            <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
            <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Webseite auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
            <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Webseite, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
            <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>
            <p>
                <img src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg" alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%" />
            </p>
            <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>
            <p>So können zum Beispiel Cookie-Daten aussehen:</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> _ga<br />
                <strong class="adsimple-121729848">Wert:</strong> GA1.2.1326744211.152121729848-9<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Unterscheidung der Webseitenbesucher<br />
                <strong class="adsimple-121729848">Ablaufdatum:</strong> nach 2 Jahren</p>
            <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">Mindestens 4096 Bytes pro Cookie</li>
                <li class="adsimple-121729848">Mindestens 50 Cookies pro Domain</li>
                <li class="adsimple-121729848">Mindestens 3000 Cookies insgesamt</li>
            </ul>
            <h3 class="adsimple-121729848">Welche Arten von Cookies gibt es?</h3>
            <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
            <p>Man kann 4 Arten von Cookies unterscheiden:</p>
            <p>
                <strong class="adsimple-121729848">Unerlässliche Cookies<br />
                </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Webseite sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
            <p>
                <strong class="adsimple-121729848">Zweckmäßige Cookies<br />
                </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Webseite bei verschiedenen Browsern gemessen.</p>
            <p>
                <strong class="adsimple-121729848">Zielorientierte Cookies<br />
                </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
            <p>
                <strong class="adsimple-121729848">Werbe-Cookies<br />
                </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
            <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
            <h3 class="adsimple-121729848">Wie kann ich Cookies löschen?</h3>
            <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Webseite die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
            <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
            <p>
                <a class="adsimple-121729848" href="https://support.google.com/chrome/answer/95647?tid=121729848" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
            </p>
            <p>
                <a class="adsimple-121729848" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=121729848" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
            </p>
            <p>
                <a class="adsimple-121729848" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=121729848" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
            </p>
            <p>
                <a class="adsimple-121729848" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121729848" target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
            </p>
            <p>
                <a class="adsimple-121729848" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=121729848" target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
            </p>
            <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers.</p>
            <h3 class="adsimple-121729848">Wie sieht es mit meinem Datenschutz aus?</h3>
            <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
            <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a class="adsimple-121729848" href="https://tools.ietf.org/html/rfc6265" target="_blank" rel="nofollow noopener noreferrer">https://tools.ietf.org/html/rfc6265</a>, dem Request for Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.</p>
            <h2 id="rechte-dsgvo" class="adsimple-121729848">Rechte laut Datenschutzgrundverordnung</h2>
            <p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">Recht auf Berichtigung (Artikel 16 DSGVO)</li>
                <li class="adsimple-121729848">Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
                <li class="adsimple-121729848">Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
                <li class="adsimple-121729848">Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)</li>
                <li class="adsimple-121729848">Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
                <li class="adsimple-121729848">Widerspruchsrecht (Artikel 21 DSGVO)</li>
                <li class="adsimple-121729848">Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
            </ul>
            <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Webseite Sie unter <a class="adsimple-121729848" href="https://www.dsb.gv.at/?tid=121729848">https://www.dsb.gv.at/</a> finden und für Deutschland können Sie sich an die <a class="adsimple-121729848" href="https://www.bfdi.bund.de" target="_blank" rel="noopener noreferrer">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.</p>
            <h2 id="auswertung-des-besucherverhaltens" class="adsimple-121729848">Auswertung des Besucherverhaltens</h2>
            <p>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.</p>
            <p>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden Datenschutzerklärung.</p>
            <h2 id="tls-verschluesselung-https" class="adsimple-121729848">TLS-Verschlüsselung mit https</h2>
            <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“) um Daten abhörsicher im Internet zu übertragen.<br />
Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist – niemand kann “mithören”.</p>
            <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch Technikgestaltung <a class="adsimple-121729848" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=121729848" target="_blank" rel="noopener noreferrer">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen.<br />
Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" alt="" width="17" height="18" /> links oben im Browser links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach “Hypertext Transfer Protocol Secure wiki” um gute Links zu weiterführenden Informationen zu erhalten.</p>
            <h2 id="google-fonts-datenschutzerklaerung" class="adsimple-121729848">Google Fonts Datenschutzerklärung</h2>
            <p>Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
            <p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</p>
            <h3 class="adsimple-121729848">Was sind Google Fonts?</h3>
            <p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a class="adsimple-121729848" href="https://de.wikipedia.org/wiki/Google_LLC?tid=121729848">Google</a> Ihren Nutzern kostenlos zu Verfügung stellen.</p>
            <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>
            <h3 class="adsimple-121729848">Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
            <p>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.</p>
            <h3 class="adsimple-121729848">Welche Daten werden von Google gespeichert?</h3>
            <p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.</p>
            <p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
            <p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>
            <h3 class="adsimple-121729848">Wie lange und wo werden die Daten gespeichert?</h3>
            <p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.</p>
            <p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.</p>
            <h3 class="adsimple-121729848">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
            <p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf <a class="adsimple-121729848" href="https://support.google.com/?hl=de&tid=121729848">https://support.google.com/?hl=de&tid=121729848</a> kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
            <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a class="adsimple-121729848" href="https://developers.google.com/fonts/faq?tid=121729848">https://developers.google.com/fonts/faq?tid=121729848</a>. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.</p>
            <p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf <a class="adsimple-121729848" href="https://policies.google.com/privacy?hl=de&tid=121729848">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.</p>
            <h2 id="font-awesome-datenschutzerklaerung" class="adsimple-121729848">Font Awesome Datenschutzerklärung</h2>
            <p>Wir verwenden auf unserer Website Font Awesome des amerikanischen Unternehmens Fonticons (307 S. Main St., Suite 202, Bentonville, AR 72712, USA). Wenn Sie eine unserer Webseiten aufrufen, wird die Web-Schriftart Font Awesome (im Speziellen Icons) über das Font Awesome Content Delivery Netzwerk (CDN) geladen. So werden die Texte bzw. Schriften und Icons auf jedem Endgerät passend angezeigt. In dieser Datenschutzerklärung gehen wir näher auf die Datenspeicherung und Datenverarbeitung durch diesen Service ein.</p>
            <h3 class="adsimple-121729848">Was ist Font Awesome?</h3>
            <p>Icons spielen für Websites eine immer wichtigere Rolle. Font Awesome ist eine Web-Schriftart, die speziell für Webdesigner und Webentwickler entwickelt wurde. Mit Font Awesome können etwa Icons mit Hilfe der Stylesheet-Sprache CSS nach Belieben skaliert und gefärbt werden. Sie ersetzen so alte Bild-Icons. Font Awesome CDN ist der einfachste Weg die Icons oder Schriftarten auf Ihre Website zu laden. Dafür mussten wir nur eine kleine Code-Zeile in unsere Website einbinden.</p>
            <h3 class="adsimple-121729848">Warum verwenden wir Font Awesome auf unserer Website?</h3>
            <p>Durch Font Awesome können Inhalte auf unserer Website besser aufbereitet werden. So können Sie sich auf unserer Website besser orientieren und die Inhalte leichter erfassen. Mit den Icons kann man sogar manchmal ganze Wörter ersetzen und Platz sparen. Da ist besonders praktisch, wenn wir Inhalte speziell für Smartphones optimieren.  Diese Icons werden statt als Bild als HMTL-Code eingefügt. Dadurch können wir die Icons mit CSS genauso bearbeiten, wie wir wollen. Gleichzeitig verbessern wir mit Font Awesome auch unsere Ladegeschwindigkeit, weil es sich nur um HTML-Elemente handelt und nicht um Icon-Bilder. All diese Vorteile helfen uns, die Website für Sie noch übersichtlicher, frischer und schneller zu machen.</p>
            <h3 class="adsimple-121729848">Welche Daten werden von Font Awesome gespeichert?</h3>
            <p>Zum Laden von Icons und Symbolen wird das Font Awesome Content Delivery Network (CDN) verwendet. CDNs sind Netzwerke von Servern, die weltweit verteilt sind und es möglich machen, schnell Dateien aus der Nähe zu laden. So werden auch, sobald Sie eine unserer Seiten aufrufen, die entsprechenden Icons von Font Awesome bereitgestellt.</p>
            <p>Damit die Web-Schriftarten geladen werden können, muss Ihr Browser eine Verbindung zu den Servern des Unternehmens Fonticons, Inc. herstellen. Dabei wird Ihre IP-Adresse erkannt. Font Awesome sammelt auch Daten darüber, welche Icon-Dateien wann heruntergeladen werden. Weiters werden auch technische Daten wie etwa Ihre Browser-Version, Bildschirmauflösung oder der Zeitpunkt der ausgerufenen Seite übertragen.</p>
            <p>Aus folgenden Gründen werden diese Daten gesammelt und gespeichert:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">um Content Delivery Netzwerke zu optimieren</li>
                <li class="adsimple-121729848">um technische Fehler zu erkennen und zu beheben</li>
                <li class="adsimple-121729848">um CDNs vor Missbrauch und Angriffen zu schützen</li>
                <li class="adsimple-121729848">um Gebühren von Font Awesome Pro-Kunden berechnen zu können</li>
                <li class="adsimple-121729848">um die Beliebtheit von Icons zu erfahren</li>
                <li class="adsimple-121729848">um zu wissen, welchen Computer und welche Software Sie verwenden</li>
            </ul>
            <p>Falls Ihr Browser Web-Schriftarten nicht zulässt, wird automatisch eine Standardschrift Ihres PCs verwendet. Nach derzeitigem Stand unserer Erkenntnis werden keine Cookies gesetzt. Wir sind mit der Datenschutzabteilung von Font Awesome in Kontakt und geben Ihnen Bescheid, sobald wir näheres in Erfahrung bringen.</p>
            <h3 class="adsimple-121729848">Wie lange und wo werden die Daten gespeichert?</h3>
            <p>Font Awesome speichert Daten über die Nutzung des Content Delivery Network auf Servern auch in den Vereinigten Staaten von Amerika. Die CDN-Server befinden sich allerdings weltweit und speichern Userdaten, wo Sie sich befinden. In identifizierbarer Form werden die Daten in der Regel nur wenige Wochen gespeichert. Aggregierte Statistiken über die Nutzung von den CDNs können auch länger gespeichert werden. Personenbezogene Daten sind hier nicht enthalten.</p>
            <h3 class="adsimple-121729848">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
            <p>Font Awesome speichert nach aktuellem Stand unseres Wissens keine personenbezogenen Daten über die Content Delivery Netzwerke. Wenn Sie nicht wollen, dass Daten über die verwendeten Icons gespeichert werden, können Sie leider unsere Website nicht besuchen. Wenn Ihr Browser keine Web-Schriftarten erlaubt, werden auch keine Daten übertragen oder gespeichert. In diesem Fall wird einfach die Standard-Schrift Ihres Computers verwendet.</p>
            <p>Wenn Sie mehr über Font Awesome und deren Umgang mit Daten erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter <a class="adsimple-121729848" href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a> und die Hilfeseite unter <a class="adsimple-121729848" href="https://fontawesome.com/help?tid=121729848">https://fontawesome.com/help</a>.</p>
            <h2 id="web-analytics" class="adsimple-121729848">Web Analytics</h2>
            <p>Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens der Website-Besucher, kurz Web Analytics oder Web-Analyse genannt. Dabei werden Daten gesammelt, die der jeweilige Analytic-Tool Anbieter (auch Trackingtool genannt) speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen über das Nutzerverhalten auf unserer Website erstellt und uns als Websitebetreiber zur Verfügung gestellt. Zusätzlich bieten die meisten Tools verschiedene Testmöglichkeiten an. So können wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern am besten ankommen. Dafür zeigen wir Ihnen für einen begrenzten Zeitabschnitt zwei verschiedene Angebote. Nach dem Test (sogenannter A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt unsere Websitebesucher interessanter finden. Für solche Testverfahren, wie auch für andere Analytics-Verfahren, können auch Userprofile erstellt werden und die Daten in Cookies gespeichert werden.</p>
            <h3 class="adsimple-121729848">Warum betreiben wir Web-Analyse?</h3>
            <p>Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen für unsere Branche das besten Webangebot auf dem Markt liefern. Um dieses Ziel zu erreichen wollen wir einerseits das beste und interessanteste Angebot bieten und andererseits darauf achten, dass Sie sich auf unserer Website rundum wohlfühlen. Mit Hilfe von Webanalyse-Tools können wir das Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann entsprechend unser Webangebot für Sie und uns verbessern. So können wir beispielsweise erkennen wie alt unsere Besucher durchschnittlich sind, woher sie kommen, wann unsere Website am meisten besucht wird oder welche Inhalte oder Produkte besonders beliebt sind. All diese Informationen helfen uns die Website zu optimieren und somit bestens an Ihre Bedürfnisse, Interessen und Wünsche anzupassen.</p>
            <h3 class="adsimple-121729848">Welche Daten werden vom Analytic-Tool gespeichert?</h3>
            <p>Welche Daten genau gespeichert werden, hängt natürlich von den verwendeten Analyse-Tools ab. Doch in der Regel wird zum Beispiel gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche Buttons oder Links Sie klicken, wann Sie eine Seite aufrufen, welchen Browser sie verwenden, mit welchem Gerät (PC, Tablet, Smartphone usw.) Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn Sie damit einverstanden waren, dass auch Standortdaten erhoben werden dürfen, können auch diese durch den Webanalyse-Tool-Anbieter verarbeitet werden.</p>
            <p>Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen personenbezogene Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert (also in unkenntlicher und gekürzter Form) gespeichert. Für den Zweck der Tests, der Webanalyse und der Weboptimierung werden grundsätzlich keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder Ihre E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie erhoben werden, pseudonymisiert gespeichert. So können Sie als Person nicht identifiziert werden.</p>
            <p>Das folgende Beispiel zeigt schematisch die Funktionsweise von Google Analytics als Beispiel für client-basiertes Webtracking mit Java-Script-Code.</p>
            <p>
                <img src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg" alt="Schematischer Datenfluss bei Google Analytics" width="100%" />
            </p>
            <p>Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom Anbieter ab. Manche Cookies speichern Daten nur für ein paar Minuten bzw. bis Sie die Website wieder verlassen, andere Cookies können Daten über mehrere Jahre speichern.</p>
            <h3 class="adsimple-121729848">Die Rechtsgrundlage für die Verwendung von Analytic-Tools?</h3>
            <p>Wenn Sie eingewilligt haben, dass Drittanbieter eingesetzt werden dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung.</p>
            <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
            <p>Da bei Webseiten-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
            <p>Informationen zu speziellen Webseiten-Analytics-Tools, erhalten Sie – sofern vorhanden – in den folgenden Abschnitten.</p>
            <div>
                <strong class="adsimple-121729848">Zusammenfassung</strong>
                <br />
👥 Betroffene: Besucher der Website<br />
📓 Verarbeitete Daten: je nach Analytics-Tool Anbieter unterschiedlich, weitere Details auf Anfrage bzw. im Abschnitt zum eingesetzten Anbieter<br />
🤝 Grund für Datenverarbeitung: Auswertung der Besucherinformationen zur Optimierung des Webangebots</div>
            <h2 id="google-analytics-datenschutzerklaerung" class="adsimple-121729848">Google Analytics Datenschutzerklärung</h2>
            <p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere Website und unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking-Tool ein und informieren Sie vor allem darüber, welche Daten gespeichert werden und wie Sie das verhindern können.</p>
            <h3 class="adsimple-121729848">Was ist Google Analytics?</h3>
            <p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen. Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
            <p>Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich für unser Service interessiert.</li>
                <li class="adsimple-121729848">Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren und verbessern.</li>
                <li class="adsimple-121729848">Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie wir mehr Menschen für unser Service begeistern können.</li>
                <li class="adsimple-121729848">Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.</li>
                <li class="adsimple-121729848">Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.</li>
                <li class="adsimple-121729848">Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</li>
            </ul>
            <h3 class="adsimple-121729848">Warum verwenden wir Google Analytics auf unserer Webseite?</h3>
            <p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
            <p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website. Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und kostengünstiger durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.</p>
            <h3 class="adsimple-121729848">Welche Daten werden von Google Analytics gespeichert?</h3>
            <p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.</p>
            <p>Um mit Google Analytics unsere Website analysieren zu können, muss eine Property-ID in den Tracking-Code eingefügt werden. Die Daten werden dann in der entsprechenden Property gespeichert. Für jede neu angelegte Property ist die Google Analytics 4-Property standardmäßig. Alternativ kann man aber auch noch die Universal Analytics Property erstellen. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</p>
            <p>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
            <p>Folgende Cookies werden von Google Analytics verwendet:</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> _ga<br />
                <strong class="adsimple-121729848">Wert: </strong>2.1326744211.152121729848-5<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br />
                <strong class="adsimple-121729848">Ablaufdatum:</strong> nach 2 Jahren</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> _gid<br />
                <strong class="adsimple-121729848">Wert: </strong>2.1687193234.152121729848-1<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br />
                <strong class="adsimple-121729848">Ablaufdatum:</strong> nach 24 Stunden</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br />
                <strong class="adsimple-121729848">Wert:</strong> 1<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_ &lt;property-id&gt;.<br />
                <strong class="adsimple-121729848">Ablaufdatum: </strong>nach 1 Minute</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> AMP_TOKEN<br />
                <strong class="adsimple-121729848">Wert:</strong> keine Angaben<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen Fehler hin.<br />
                <strong class="adsimple-121729848">Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utma<br />
                <strong class="adsimple-121729848">Wert: </strong>1564498958.1564498958.1564498958.1<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br />
                <strong class="adsimple-121729848">Ablaufdatum:</strong> nach 2 Jahren</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utmt<br />
                <strong class="adsimple-121729848">Wert:</strong> 1<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Das Cookie wird wie _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der Anforderungsrate verwendet.<br />
                <strong class="adsimple-121729848">Ablaufdatum:</strong> nach 10 Minuten</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utmb<br />
                <strong class="adsimple-121729848">Wert: </strong>3.10.1564498958<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br />
                <strong class="adsimple-121729848">Ablaufdatum:</strong> nach 30 Minuten</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utmc<br />
                <strong class="adsimple-121729848">Wert:</strong> 167421564<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange gespeichert, bis Sie den Browser wieder schließen.<br />
                <strong class="adsimple-121729848">Ablaufdatum:</strong> Nach Schließung des Browsers</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utmz<br />
                <strong class="adsimple-121729848">Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br />
                <strong class="adsimple-121729848">Ablaufdatum:</strong> nach 6 Monaten</p>
            <p>
                <strong class="adsimple-121729848">Name:</strong> __utmv<br />
                <strong class="adsimple-121729848">Wert:</strong> keine Angabe<br />
                <strong class="adsimple-121729848">Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br />
                <strong class="adsimple-121729848">Ablaufdatum:</strong> nach 2 Jahren</p>
            <p>
                <strong class="adsimple-121729848">Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.</p>
            <p>Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:</p>
            <p>
                <strong class="adsimple-121729848">Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.</p>
            <p>
                <strong class="adsimple-121729848">Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.</p>
            <p>
                <strong class="adsimple-121729848">Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
            <p>
                <strong class="adsimple-121729848">Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.</p>
            <p>
                <strong class="adsimple-121729848">IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form dargestellt, damit keine eindeutige Zuordnung möglich ist.</p>
            <p>
                <strong class="adsimple-121729848">Standort:</strong> Über die IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.</p>
            <p>
                <strong class="adsimple-121729848">Technische Informationen:</strong> Zu den technischen Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.</p>
            <p>
                <strong class="adsimple-121729848">Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
            <p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</p>
            <h3 class="adsimple-121729848">Wie lange und wo werden die Daten gespeichert?</h3>
            <p>Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a class="adsimple-121729848" href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank" rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
            </p>
            <p>Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
            <p>Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. Für andere sogenannte Ereignisdaten haben wir die Möglichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu wählen.</p>
            <p>Bei Universal Analytics-Properties ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten standardisiert eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:</p>
            <ul class="adsimple-121729848">
                <li class="adsimple-121729848">Löschung nach 14 Monaten</li>
                <li class="adsimple-121729848">Löschung nach 26 Monaten</li>
                <li class="adsimple-121729848">Löschung nach 38 Monaten</li>
                <li class="adsimple-121729848">Löschung nach 50 Monaten</li>
                <li class="adsimple-121729848">Keine automatische Löschung</li>
            </ul>
            <p>Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zurückgesetzt, wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.</p>
            <p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.</p>
            <h3 class="adsimple-121729848">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
            <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on können Sie unter <a class="adsimple-121729848" href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.</p>
            <p>Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics) deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser eine eigene Anleitung:</p>
            <p>
                <a class="adsimple-121729848" href="https://support.google.com/chrome/answer/95647?tid=121729848" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
            </p>
            <p>
                <a class="adsimple-121729848" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=121729848" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
            </p>
            <p>
                <a class="adsimple-121729848" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=121729848" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
            </p>
            <p>
                <a class="adsimple-121729848" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121729848" target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
            </p>
            <p>
                <a class="adsimple-121729848" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=121729848" target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
            </p>
            <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen Dienstleister gibt.</p>
            <p>Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: <a class="adsimple-121729848" href="http://www.google.com/analytics/terms/de.html" target="_blank" rel="noopener noreferrer">http://www.google.com/analytics/terms/de.html</a> und <a class="adsimple-121729848" href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
            <h2 id="google-tag-manager-datenschutzerklaerung" class="adsimple-121729848">Google Tag Manager Datenschutzerklärung</h2>
            <p>Für unsere Website verwenden wir den Google Tag Manager des Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Dieser Tag Manager ist eines von vielen hilfreichen Marketing-Produkten von Google. Über den Google Tag Manager können wir Code-Abschnitte von diversen Trackingtools, die wir auf unserer Webseite verwenden, zentral einbauen und verwalten.</p>
            <p>In dieser Datenschutzerklärung wollen wir Ihnen genauer erklären was der Google Tag Manager macht, warum wir ihn verwenden und in welcher Form Daten verarbeitet werden.</p>
            <h3 class="adsimple-121729848">Was ist der Google Tag Manager?</h3>
            <p>Der Google Tag Manager ist ein Organisationstool, mit dem wir Webseiten-Tags zentral und über eine Benutzeroberfläche einbinden und verwalten können. Als Tags bezeichnet man kleine Code-Abschnitte, die beispielsweise Ihre Aktivitäten auf unserer Webseite aufzeichnen (tracken). Dafür werden JavaScript-Code-Abschnitte in den Quelltext unserer Seite eingesetzt. Die Tags stammen oft von google-internen Produkten wie Google Ads oder Google Analytics, aber auch Tags von anderen Unternehmen können über den Manager eingebunden und verwaltet werden. Solche Tags übernehmen unterschiedliche Aufgaben. Sie können Browserdaten sammeln, Marketingtools mit Daten füttern, Buttons einbinden, Cookies setzen und auch Nutzer über mehrere Webseiten hinweg verfolgen.</p>
            <h3 class="adsimple-121729848">Warum verwenden wir den Google Tag Manager für unserer Webseite?</h3>
            <p>Wie sagt man so schön: Organisation ist die halbe Miete! Und das betrifft natürlich auch die Pflege unserer Webseite. Um unsere Webseite für Sie und alle Menschen, die sich für unsere Produkte und Dienstleistungen interessieren, so gut wie möglich zu gestalten, brauchen wir diverse Trackingtools wie beispielsweise Google Analytics. Die erhobenen Daten dieser Tools zeigen uns, was Sie am meisten interessiert, wo wir unsere Leistungen verbessern können und welchen Menschen wir unsere Angebote noch zeigen sollten. Und damit dieses Tracking funktioniert, müssen wir entsprechende JavaScript-Codes in unsere Webseite einbinden. Grundsätzlich könnten wir jeden Code-Abschnitt der einzelnen Tracking-Tools separat in unseren Quelltext einbauen. Das erfordert allerdings relativ viel Zeit und man verliert leicht den Überblick. Darum nützen wir den Google Tag Manager. Wir können die nötigen Skripte einfach einbauen und von einem Ort aus verwalten. Zudem bietet der Google Tag Manager eine leicht zu bedienende Benutzeroberfläche und man benötigt keine Programmierkenntnisse. So schaffen wir es, Ordnung in unserem Tag-Dschungel zu halten.</p>
            <h3 class="adsimple-121729848">Welche Daten werden vom Google Tag Manager gespeichert?</h3>
            <p>Der Tag Manager selbst ist eine Domain, die keine Cookies setzt und keine Daten speichert. Er fungiert als bloßer „Verwalter“ der implementierten Tags. Die Daten erfassen die einzelnen Tags der unterschiedlichen Web-Analysetools. Die Daten werden im Google Tag Manager quasi zu den einzelnen Tracking-Tools durchgeschleust und nicht gespeichert.</p>
            <p>Ganz anders sieht das allerdings mit den eingebundenen Tags der verschiedenen Web-Analysetools, wie zum Beispiel Google Analytics, aus. Je nach Analysetool werden meist mit Hilfe von Cookies verschiedene Daten über Ihr Webverhalten gesammelt, gespeichert und verarbeitet. Dafür lesen Sie bitte unsere Datenschutztexte zu den einzelnen Analyse- und Trackingtools, die wir auf unserer Webseite verwenden.</p>
            <p>In den Kontoeinstellungen des Tag Managers haben wir Google erlaubt, dass Google anonymisierte Daten von uns erhält. Dabei handelt es sich aber nur um die Verwendung und Nutzung unseres Tag Managers und nicht um Ihre Daten, die über die Code-Abschnitte gespeichert werden. Wir ermöglichen Google und anderen, ausgewählte Daten in anonymisierter Form zu erhalten. Wir stimmen somit der anonymen Weitergabe unseren Website-Daten zu. Welche zusammengefassten und anonymen Daten genau weitergeleitet werden, konnten wir – trotz langer Recherche – nicht in Erfahrung bringen. Auf jeden Fall löscht Google dabei alle Infos, die unsere Webseite identifizieren könnten. Google fasst die Daten mit Hunderten anderen anonymen Webseiten-Daten zusammen und erstellt, im Rahmen von Benchmarking-Maßnahmen, Usertrends. Bei Benchmarking werden eigene Ergebnisse mit jenen der Mitbewerber verglichen. Auf Basis der erhobenen Informationen können Prozesse optimiert werden.</p>
            <h3 class="adsimple-121729848">Wie lange und wo werden die Daten gespeichert?</h3>
            <p>Wenn Google Daten speichert, dann werden diese Daten auf den eigenen Google-Servern gespeichert. Die Server sind auf der ganzen Welt verteilt. Die meisten befinden sich in Amerika. Unter <a class="adsimple-121729848" href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank" rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a> können Sie genau nachlesen, wo sich die Google-Server befinden.</p>
            <p>Wie lange die einzelnen Tracking-Tools Daten von Ihnen speichern, entnehmen Sie unseren individuellen Datenschutztexten zu den einzelnen Tools.</p>
            <h3 class="adsimple-121729848">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
            <p>Der Google Tag Manager selbst setzt keine Cookies, sondern verwaltet Tags verschiedener Tracking-Webseiten. In unseren Datenschutztexten zu den einzelnen Tracking-Tools, finden Sie detaillierte Informationen wie Sie Ihre Daten löschen bzw. verwalten können.</p>
            <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen Dienstleister gibt.</p>
            <p>Wenn Sie mehr über den Google Tag Manager erfahren wollen, empfehlen wir Ihnen die FAQs unter <a class="adsimple-121729848" href="https://www.google.com/intl/de/tagmanager/faq.html" target="_blank" rel="noopener noreferrer">https://www.google.com/intl/de/tagmanager/faq.html</a>.</p>
Alle Texte sind urheberrechtlich geschützt.<p>Quelle: Erstellt mit dem <a href="https://www.adsimple.at/datenschutz-generator/" rel="noopener noreferrer"  title="Datenschutz Generator von AdSimple">Datenschutz Generator</a> von AdSimple</p>

        </div>);
}

export default Contact;